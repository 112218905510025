import React from 'react';
import './Quote.css';

function GetQuote() {
    let header = 'LAZY.';
    let content = 'Progress is made by lazy men looking for easier ways to do things.';
    return (
      <div className="Quote" data-testid="quote">
        <div className="Quote__Header" data-testid="quote__header">
          {header}
        </div>
        <div className="Quote__Content" data-testid="quote__content">
          {content}
        </div>
      </div>
    );
  }

export default GetQuote;