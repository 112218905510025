import React from 'react';
import './Goodbye.css';
import * as images from '../../../assets/images';

function logConsoleStuff() {
    console.clear();
    console.log('%c bro inga enna paakreenga, periya hacker ah irupeenga polaye? ', 'background: #222; color: #bada55');
    console.log('%c neenga neneikra madhiri edhum illa ', 'background: #222; color: #bada55');
    console.log('%c https://lh3.googleusercontent.com/-J5sMX8-1vOI/WhhJNuCyMLI/AAAAAAAAETg/ppmFFm10lb8uXyaPGqUAXl4TMN5D2XiMwCHMYCw/s640/FB_IMG_15115406595185315.jpg ', 'background: #222; color: #eeeee');
    console.log('%c ... ', 'background: #222; color: #bada55');
    console.log('%c aana paravailla, next ctf la neenga dhan 🏆 ', 'background: #222; color: #bada55');
}

function Goodbye () {
    logConsoleStuff();
    
    let goodbyeImages = [images.goodbyeTeam1, images.goodbyeTeam2, images.goodbyeTeam3];
    let randomImage = goodbyeImages[Math.floor(Math.random() * goodbyeImages.length)];
    return (
        <div id='goodbye'>
            <div id='first-para'>
                <h1>Hello there!</h1>  <br />
                If you're here, we probably worked together for a while. Maybe <i>years</i>. And I learnt a lot from you. <br />
                Let's keep in touch! 😁  
            </div>

            <img id='team-img' src={randomImage} alt='surprise' />
            <div id='img-caption-mobile'>pls pinch to zoom in, refresh for another image. i'm not a UI guy, now you know why.</div>
            <div id='img-caption-monitor'>refresh the page for another image. i'm not a UI guy, now you know why.</div>

            <div id='contact-options'>
                <i>You could</i>
                <ul>
                    <li>👉🏾 Send me <a href='https://www.linkedin.com/in/dvsj/' target='_blank'>a message on LinkedIn</a> <span className='gb-commentary'>(you won't get <i>10 lessons you can learn from Kohli's 100</i> kind of posts from me, promise)</span></li>
                    <li>👉🏾 Share <a href='https://www.instagram.com/catalan.cabbage/' target='_blank'>memes on Insta</a> <span className='gb-commentary'>(reels? stories? what's it called these days?)</span></li>
                    <li>👉🏾 DM me <a href='https://twitter.com/dvsj_in' target='_blank'>on 𝕏</a> <span className='gb-commentary'>(twitter twitter)</span></li>
                    <li>👉🏾 Go old school and email me at <span className='highlight'>dav.is@zohomail.in</span></li>
                    <li>👉🏾 Whatsapp me on <span className='highlight'>9486493469</span></li>
                </ul>
            </div>

            <div id='get-contact'>
                You know I write ocassionally - if you'd like me to tell you <s>when</s> if I ever write something, share your contact here!
                <div id='contact-input-container'>
                    <ContactCollectionInput />
                </div>
            </div>

            <div id='gb-footer-monitor'>Sorry for the weird ⭐ cursor. Always wanted to try it, and...why not?</div>
        </div>
    )
}

function ContactCollectionInput() {
    const addContact = (event) => {
        event.preventDefault(); // Prevents the default form submission behavior
        const contactType = (document.getElementById('contact-type') as HTMLSelectElement)?.value;
        const contactValue = (document.getElementById('contact-value') as HTMLSelectElement)?.value;
        console.log(contactType, contactValue);
        if (contactValue == null || contactValue == '') {
            alert('Please enter a valid contact value! (yes, not the best alert, but...works!)');
            return;
        }
        let data = JSON.stringify({
            email: btoa(contactType + '...' + contactValue)
        });

        let xhr = new XMLHttpRequest();
            xhr.addEventListener("readystatechange", function() {
                if(this.readyState === 4) {
                    console.log(this.responseText);
                }
            }); 

            // xhr.open("POST", " http://localhost:3000/server/register-newsletter/register");
            xhr.open("POST", "https://dvsj-newsletter-60010051318.catalystserverless.in/server/register-newsletter/register");
            // xhr.open("POST", "https://dvsj-newsletter-60010051318.development.catalystserverless.in/server/register-newsletter/register");
            xhr.setRequestHeader("Content-Type", "application/json");

            xhr.send(data);
    };

    let placeholder = 'your handle here';
    const contactTypeChanged = () => {
        const contactTypeElement = (document.getElementById('contact-type') as HTMLSelectElement);
        const contactValueElement = (document.getElementById('contact-value') as HTMLInputElement);
        if (contactTypeElement == null || contactValueElement == null) return;
        
        const contactType = contactTypeElement.value;
        if (contactType == 'Email') {
            placeholder = 'your email here';
        } else if (contactType == 'LinkedIn') {
            placeholder = 'your LinkedIn handle here';
        } else if (contactType == 'Instagram') {
            placeholder = 'your Instagram handle here';
        } else if (contactType == 'Whatsapp') {
            placeholder = 'your tring tring here';
        } else if (contactType == 'Surprise') {
            placeholder = 'hmmmmm...';
        } else {
            placeholder = 'your handle here';
        }
        contactValueElement.placeholder = placeholder;
    }
    contactTypeChanged();
    return (
        <div id='contact-input'>
            <select name="contact-type" id="contact-type" onChange={contactTypeChanged}>
                <option value="Email">your email</option>
                <option value="LinkedIn">LinkedIn profile</option>
                <option value="Instagram">Instagram @</option>
                <option value="Whatsapp">Phone/Whatsapp</option>
                <option value="Surprise">Surprise</option>
            </select>
            <input type='text' id='contact-value' placeholder={placeholder}></input>
            <button type="submit" value="Submit" onClick={addContact}>Send! ►</button>
        </div>
    )
}

    

export default Goodbye;

