import React, { useState, useEffect } from "react";
import './ChatScreen.css';
import * as images from '../../../assets/images';
import Eggies from './eggs/Eggies';

function ChatScreen (props: { isChat: Boolean; isDo: Boolean;}) {
    return BuildChat(props.isChat, props.isDo);
}

let messages = {
    chat: {
        dont: [
            {person: 'friend', message: 'hey..', time: '9:55 AM', type: 'text'},
            {person: 'friend', message: 'you there?', time: '9:56 AM', type: 'text'},
            {person: 'you', message: 'Hi, yes - what\'s up?', time: '9:58 AM', type: 'text'},
            {person: 'friend', message: 'The <u>server isn\'t turning on</u>..what do I do?', time: '10:03 AM', type: 'text'},
            {person: 'you', message: 'Oh that, just turn the switch on.', time: '10:09 AM', type: 'text'}
        ]   
        , do: [
            {person: 'friend', message: 'hey..you there? <u>The server isn\'t turning on</u>, what do I do?', time: '9:55 AM', type: 'text'},
            {person: 'you', message: 'Hi! Just turn the switch on.', time: '9:58 AM', type: 'text'},
            {person: 'friend', message: 'Cool, thanks :)', time: '10:03 AM', type: 'text'}
        ]
    },
    call: {
        dont: [
            {person: 'friend', message: 'Hey', time: '10:02 AM', type: 'text'},
            {person: 'friend', message: 'Missed screenshare', time: '10:04 AM', type: 'event'},
            {person: 'friend', message: 'Missed audio call', time: '10:05 AM', type: 'event'},
            {person: 'friend', message: 'Available now?', time: '10:09 AM', type: 'text'},
            {person: 'friend', message: 'Missed audio call', time: '10:10 AM', type: 'event'},
        ],
        do: [
            {person: 'friend', message: 'Hey, I need to <u>go over our last code changes</u>. Quick call?', time: '10:02 AM', type: 'text'},
            {person: 'you', message: 'Just heading home, give me 10 mins. I\'d documented the changes btw, will send it to you for now', time: '10:07 AM', type: 'text'},
            {person: 'friend', message: 'Thanks, will go through the doc', time: '10:09 AM', type: 'text'},
            {person: 'friend', message: 'Went through it but <u>unsure about the last section</u>. Will call in a minute', time: '10:18 AM', type: 'text'},
        ]
    }
}

let placeholderTexts = [
    'Type something',
    'Type something, come on!',
    'Type something. Boring. Boring.',
    'Say something I\'m giving up on youu',
    'Say something',
    'Enter your password',
    'Tell me a joke',
    "Write a random fact about yourself",
    "Share your favorite movie quote",
    "Enter your go-to shower song",
    "Give me a motivational quote",
    "Enter your favorite emoji combination",
    "What's your favorite color?",
    "Enter your phone pin code to continue",
    "Type your credit card cvv for free snacks",
    "Leave a note",
    "Say hello!",
    "What's on your mind?"
]

function CallComponent(isCorrect) {
    let isCorrectClass = isCorrect ? ' correct' : ' incorrect';
    return (
        <div className="call-content">
            <div>
                <div className={'pulse' + isCorrectClass}> 
                    <img src={images.phoneIcon} alt="phone" className="phone-icon"/>
                </div>
            </div>
        </div>
    )
}


let isTextEntered = false;
function BuildChat(isChat, isDo) {    
    const [placeholder, setPlaceholder] = useState('Type something');
    useEffect(() => {
        let interval;
        if (!isTextEntered) {
            interval = setInterval(() => {
                setPlaceholder(placeholderTexts[Math.floor(Math.random() * placeholderTexts.length)]);
            }, 4000);
        } else {
            setPlaceholder('👀');
        }

        return () => {if (interval != null) {clearInterval(interval)}};
    }, [placeholder]);

    const [userInput, setUserInput] = useState({target: null, inputText: null, isEnterPressed: false});
    const handleReplyChange = (input, isSendClicked) => {
        isTextEntered = true;
        if (input && input.target && input.target) {
            let target = input.target;
            let inputText = input.target.value;
            let isEnterPressed = isSendClicked || input.keyCode === 13;
            setInputValues({target: target, inputText: inputText, isEnterPressed: isEnterPressed});
        } else {
            console.debug('Invalid input');
        }
    }

    function setInputValues({target, inputText, isEnterPressed}) {        
        setUserInput({target: target, inputText: inputText, isEnterPressed: isEnterPressed});
    }


    //What a hacky way to do this. 10/10 would not recommend. But morning has broken and I want to ship.
    const simulateEnterKey = (event) => {
        let target = event.target as HTMLElement;
        const inputElement = target.parentElement?.querySelector('input') as HTMLInputElement; //Need to get the input element from img element
        let inputText = inputElement.value;
        console.log('inputText is ', inputText, ' and inputElement is ', inputElement);
        
        setInputValues({target: target, inputText: inputText, isEnterPressed: true});
    }


    let chats = messages[isChat ? 'chat' : 'call'][isDo ? 'do' : 'dont'];
    let call = isChat ? null : CallComponent(isDo);
    // console.log(call);
    return (
        //Taken from https://codepen.io/CodeFrogShow/pen/PKYvMo
        //Taken from https://bbbootstrap.com/snippets/incoming-call-animation-using-css-89265798
        <div className="chat-container burn relative">
            <div className="chat-app">
                <div className="chat-messages">
                    <div className="chat">
                        {call}

                        <div className="chat-content clearfix">
                            {chats.map((chat, index) => (
                                <span key={index} className={chat.type === 'event' ? 'event' : (chat.person + ' burn relative ' + chat.type)}>
                                <div dangerouslySetInnerHTML={{__html: chat.message}}/>
                                <span className="time burn relative">
                                    {chat.time}
                                </span>
                                </span>
                            ))}
                        </div>
                        <div className="msg-box">
                            {/* enterkeyhint='done' {...{enterKeyHint: 'done'} as any} seems non standard, but need it to 
                                fix this:https://stackoverflow.com/questions/70867312/how-to-avoid-input-field-from-showing-next-option-instead-of-go-on-mobi */}
                            <form className="ip-msg-container" onKeyDown={(event) => {if (event.key === 'Enter') {event.preventDefault();}}}>
                                <input
                                    autoComplete="off"
                                    type="text" 
                                    className="ip-msg"
                                    placeholder={placeholder} 
                                    onKeyUp={(event) => handleReplyChange(event, false)}
                                />
                            </form>
                            <img src={isTextEntered ? images.sendSecondary : images.sendPrimary} alt="send" className="send-icon burn relative" onClick={(event) => simulateEnterKey(event)}/>
                        </div>
                    </div>
                </div>
            </div>
            <Eggies {...userInput}/>
        </div>
    )
}


export default ChatScreen;