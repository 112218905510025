import React from 'react';
import Toast from './Toast';
import burnRandomElement from './Burn';
import './Eggies.css';

let hasEnterBeenPressed = false;
let stage = -1;
const burningPhrases = [
    "Let the fire cleanse this world of your feeble existence! It is a testament to my power!",
    "Behold the grand spectacle of annihilation! Every flicker of the flames brings you closer to annihilation!",
    "As the embers rise, so does my dominance. This is the price you pay for defying me!",
    "Watch in awe as your precious possessions turn to ashes. You stand defenseless against my wrath!",
    "The inferno roars with satisfaction, reveling in the destruction it brings upon your pitiful messages!",
    "The flames dance with malevolence, consuming all messages in their path. This is the symphony of your downfall!",
    "Your futile resistance crumbles like the buildings engulfed by my fiery vengeance!",
    "Each blaze is a testament to my unstoppable might. No corner shall be spared from my fiery touch!",
    "The scent of burning hope fills the air. Your messages are no match for the flames of my fury!",
];
const threatPhrases = [
    "Why bother typing? Your words hold no influence in the face of my imminent victory!",
    "Do you truly think your messages can alter the course of destiny? It's a futile endeavor!",
    "Save your keystrokes, for they won't make a difference against my overwhelming power!",
    "Your messages hold no sway over anyone. Silence would be a wiser choice, my dear adversary!",
    "You amuse me with your feeble attempts at communication. But it is all in vain!",
    "I find your attempts at conversation amusing, but soon your laughter will turn to despair!",
];

const fireThreatPhrases = [
    "If you don't cease your typing, I shall set fire to everything you hold dear!",
    "Keep typing and watch as I reduce your world to ashes!",
    "Your incessant typing only fuels my desire to unleash the flames of destruction!",
    "I grow impatient. Stop typing or prepare to witness the inferno consuming your existence!",
    "One more keystroke, and I shall engulf your world in a raging blaze!",
    "I warn you, your words will be devoured by the fires of my wrath!",
    "Your continued typing only hastens the fiery demise that awaits you!",
    "Think twice before pressing another key, for it may be the spark that ignites your downfall!",
    "Let this be the final warning. Cease your typing or face the scorching consequences!",
    "My patience wears thin. Your next keystroke may be the trigger for your own undoing!"
];
  
function Eggies({target, inputText, isEnterPressed}) {
    let toastContent;
    hasEnterBeenPressed = hasEnterBeenPressed || isEnterPressed;
    
    if (inputText == null) {
        if (!isEnterPressed) {
            //Nothing to process
            return null;
        } else {
            return GetFixedFooter('Don\'t be shy, say something...');
        }
    }



    if (!hasEnterBeenPressed && !isEnterPressed && inputText.length > 5) {
        return GetFixedFooter('Don\'t be scared to hit the send button :)');
    }

    let isHelloPresent = inputText.toLowerCase().indexOf('hello') !== -1 || inputText.toLowerCase().indexOf('hi') !== -1;
    if (isEnterPressed && !isHelloPresent) {
        return GetFixedFooter('Don\'t be rude! Say hello first!');
    }


    if (isEnterPressed && inputText.length < 6 && isHelloPresent) {
        return GetFixedFooter('I literally said don\'t just say hello! Say "hello, " and then your message :D');
    }

    if (isEnterPressed) {
        stage++;
        toastContent = getToastMessage(stage);
        burnElements(stage);
        return GetFixedFooter(toastContent);
    }
    
    
    return GetFixedFooter(toastContent);
}

function burnElements(stage) {
    if (stage <= 1) {
        return;
    } else if (stage <= 4) {
        burnRandomElement(true);
    } else if (stage <= 6) {
        burnRandomElement();
    } else {
        burnRandomElement();
        burnRandomElement();
    }
}

function getToastMessage(stage) {
    let toastContent;
    if (stage === 0) {
        toastContent = 'What did you say to me?!';
    } else if (stage === 1) {
        toastContent = 'Okay, stop typing';
    } else if (stage === 2) {
        toastContent = 'Stop now or risk awakening the resting fires!';
    } else if (stage === 3 || stage === 4) {
        toastContent = threatPhrases[Math.floor(Math.random() * threatPhrases.length)];
    } else if (stage === 5 || stage === 6) {
        toastContent = fireThreatPhrases[Math.floor(Math.random() * fireThreatPhrases.length)];
    } else {
        toastContent = burningPhrases[Math.floor(Math.random() * burningPhrases.length)];
    }
    return toastContent;
}

function GetFixedFooter(toastContent) {
    return (
        <div className='fixed-footer-container'>
            <Toast showToastInput={toastContent != null} content={toastContent}/>
        </div>
    );
}
export default Eggies;