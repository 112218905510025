import React from 'react';
import './Portal.css'
import * as images from '../../../assets/images';


const Portal = () => {
  return (
    <div className="container">
      <img className='portal' src={images.portal} alt="rick & morty portal" onClick={openBlog}></img>
    </div>
  )
};

let messages = [
  "Wubba Lubba Dub Dub!",
  "Uh oh! Somersault jump!",
  "I'm Mr. Meeseeks, look at me!",
  "SHOW ME WHAT YOU GOT!",
  "I'm the one true Morty!",
  'and away we go!',
  'twenty minutes adventure!',
  'i turned myself into a blog morty!',
  'this page will self destruct in 3...2...',
  'wachu lookin at pickle rick?',
  'what is my purpose? you open the blog',
  'what does this button do?',
  'the portal to the blog dimension'
]
function openBlog() {
  alert(messages[Math.floor(Math.random() * messages.length)]);
}

export default Portal;

