import React, { useState } from "react";

import './Blog.css'
import Portal from "./portal/Portal";

const NotificationsSignUp = () => {
  const [selectedOption, setSelectedOption] = useState('');

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOption = event.target.value;
    setSelectedOption(selectedOption);
  };

  const placeholders = {
    'email': 'your@here',
    'linkedin': 'name so I can CFBR',
    'reddit': 'u/DID_WHAT',
    'twitter': 'your 𝕏 handle',
    'speedpost': 'so i can send pizza',
    'other': 'anything goes'
  }

  const handleInputKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSignUp();
    }
  }

  const handleSignUp = () => {
    const notificationType = (document.getElementById('notification-type') as HTMLInputElement)?.value;
    const notificationInput = (document.getElementById('notification-input') as HTMLInputElement)?.value;

    if (notificationInput === '' || notificationInput === null) {
      alert('Please enter your contact. Don\'t leave me sending things into the void!');
      return;
    }
    // https://dvsj-subscribeToNewsletter.web.val.run?userContact=dav.is@zohomail.in&userData=123
    const userData = {
      notificationType: notificationType,
      notificationInput: notificationInput
    }
    const url = new URL('https://dvsj-subscribeToNewsletter.web.val.run');
    const params = new URLSearchParams({
      userContact: notificationInput,
      userData: JSON.stringify(userData)
    });
    url.search = params.toString();

    fetch(url.toString())
      .then(response => response.json())
      .then(data => alert('Thanks! See you (hopefully) this year .-.'))
      .catch(error => alert('Oops, there was an error. Please share a screenshot of this with me! ---' + error));
  }

  const signDownMessages = [
    "Don't like a post I send? Get your money back, no questions asked. 😌",
    "You're holding out on me? I thought we were friends! 😢",
    "What if you win the lottery but I'm not able to send you the million dollars because you didn't give me your email?",
    "Good! Don't just go about giving your email around (except when I ask for it, that is)",
    "Come on, you have so much spam anyway. Will another make a difference? 🥹",
    "(╯°□°)╯︵ ┻━┻",
    "( ｡ •̀ ᴖ •́ ｡)",
    "┻━┻︵ \\(°□°)/ ︵ ┻━┻"
  ]
  const handleSignDown = () => {
    alert(signDownMessages[Math.floor(Math.random() * signDownMessages.length)]);
  }

  return (
    <div>
      <div id="notification-input__label">Get notified when I write (or teach me how to center this)</div> 
      <div id="notification-inputs">        
        <select id="notification-type" onChange={handleSelectChange}>
          <option value="email">Email</option>
          <option value="linkedin">LinkedIn</option>
          <option value="reddit">Reddit</option>
          <option value="twitter">Twitter</option>
          <option value="speedpost">Postal address</option>
          <option value="other">Anything else</option>
        </select>

        <input type="text" onKeyUp={handleInputKeyUp} placeholder={placeholders[selectedOption || "email"]} id="notification-input"/>
        <div>
          <button id="sign-up-button" onClick={handleSignUp}>Submit</button>
          <button id="sign-down-button" onClick={handleSignDown}>Noope</button>
        </div>
      </div>
      
      <div id="rick-dialogue">
        <b>Rick:</b> Aw jeez, Morty, don't sweat it. This guy, he's like, hardly ever puts pen on paper.
        We're talking less writing than Jerry's got brain cells, Morty. You know what I mean?
      </div>
    </div>
  )
}

const Blog = () => {  
  setTimeout(() => {
      document.getElementById('blog-divider')?.scrollIntoView({behavior: 'smooth', block: 'center'});
  }, 200)
  return (
    <div className='slow-intro'>
      <div id="blog-portal">
        <Portal/>
      </div>
      <div id="blog-section">
        <div id="notifications-sign-up">
          <NotificationsSignUp/>
        </div>
        <div id="blog-divider">---👇🏽---</div>
        <div id="blog-list">
          <a className="blog-list__element" href="https://www.reddit.com/r/developersIndia/comments/1cbruxt/hashing_explained_from_scratch_for_noobs_like_me/" target="_blank" rel="noreferrer">
            <div className="blog-list__top-bar">
              <div className="blog-list__title">
                What's Hashing?
              </div>
              <div className="blog-list__date">
                  24th April '24, Reddit
                </div>
            </div>
            <div className="blog-list__desc">
              a gentle introduction - understand passwords, checksums and bitcoin in 5 minutes. <br/>or money back guaranteed.
              <div className="blog-list__subtext">no, this is NOT related to hash brownies.</div> 
            </div>
          </a>
          <a className="blog-list__element" href="https://www.reddit.com/r/developersIndia/comments/1ejno9i/tech_quickie_obfuscation_explained_in_2_mins_or/" target="_blank" rel="noreferrer">
            <div className="blog-list__top-bar">
              <div className="blog-list__title">
                Obfuscation explained.
              </div>
              <div className="blog-list__date">
                  4th August '24, Reddit
                </div>
            </div>
            <div className="blog-list__desc">
              ˙ʇxǝʇ pǝʇɐɔsnɟqo sᴉ sᴉɥʇ<br/>  
              ˙ǝsuǝs sǝʞɐɯ ʇnq pɐǝɹ oʇ pɹɐɥ. 𝔲𝐬ẸʳŇ𝔞м𝐞s ƃuᴉʎouuɐ ǝsoɥʇ ǝʞᴉl 
              <div className="blog-list__subtext">not a replacement for security!</div> 
            </div>
          </a>
          <a className="blog-list__element" href="https://www.reddit.com/r/developersIndia/comments/1burmv7/my_journey_from_0_to_today_to_help_others_on/" target="_blank" rel="noreferrer">
            <div className="blog-list__top-bar">
              <div className="blog-list__title">
                On upskilling, learning, building. 
              </div>
              <div className="blog-list__date">
                  3rd April '24, Reddit
                </div>
            </div>
            <div className="blog-list__desc">
              things i've done to get from mech to tech. <br/>web apps, mobile apps, desktop apps, scripts...because why not?
              <div className="blog-list__subtext">i just mostly got lucky tho</div> 
            </div>
            
          </a>
        </div>
      </div>
    </div>
  )
};

export default Blog;